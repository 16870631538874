<template>
  <div class="workDetail">
    <Nav></Nav>
    <li class="con" v-for="(item, index) in list" :key="index">
      <div class="img">
        <img v-lazy="item.url" alt="" />
      </div>
      <div class="text" v-if="index == 0">
        <div class="left">
          <div class="title">鼓浪嶼黃勝記伴手禮 酥饼系列包装</div>
          <p class="chinese"></p>
          <p class="chinese-min"> 厦门老字号鼓浪屿记忆<br>黄胜凤梨酥 | 黄胜记红豆饼 | 黄胜记板栗饼 | 黄胜记绿豆饼 | 黄胜记椰蓉饼 | 黄胜记肉松饼 | 黄胜记绿茶饼
          </p>
          <p class="english">
            At Leaping Creative we focus on retail brand experience design,with the believe that a good design should be both creative and
            problem solving, aiming to build unique images for each brand.
          </p>
        </div>
        <div class="right">
          <p><span>Creative Director</span>：黄志勤</p>
          <p><span>Illustration</span>：QucóòoI 三穗</p>
          <p><span>Designer</span>：QucóòoI 三穗</p>
        </div>
      </div>
      <!-- <div class="text" v-if="index == 1">
      <div class="left">
      <p class="chinese-min"></p>
      </div>
      </div> -->
    </li>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "../components/Nav";
import Footer from  '../components/FooterInside.vue'
export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      list: [
              {
                    url: require('../assets/work-2/x1.jpg')
                },
                {
                    url: require('../assets/work-2/x2.jpg')
                },
                {
                    url: require('../assets/work-2/x3.jpg')
                },
                {
                    url: require('../assets/work-2/x4.jpg')
                },
                {
                    url: require('../assets/work-2/x5.jpg')
                },
                {
                    url: require('../assets/work-2/x6.jpg')
                },
                {
                    url: require('../assets/work-2/x7.jpg')
                },
                {
                    url: require('../assets/work-2/x8.jpg')
                },
                {
                    url: require('../assets/work-2/x9.jpg')
                },
                {
                    url: require('../assets/work-2/x10.jpg')
                },
                {
                    url: require('../assets/work-2/x11.jpg')
                },
                {
                    url: require('../assets/work-2/x12.jpg')
                },
                {
                    url: require('../assets/work-2/x13.jpg')
                },
                {
                    url: require('../assets/work-2/x14.jpg')
                },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.workDetail {
  .con {
    width: 100%;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 100%;
      }

      img[lazy="loading"] {
        width: 10%;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      padding: 0.3rem;

      .left {
        width: 850px;

        .title {
          font-size: 26px;
          margin-bottom: 30px;
        }

        .chinese {
          font-size: 15px;
          line-height: 0px;
          margin-bottom: 0px;
          font-weight: 400;
        }

        .chinese-min {
          font-size: 15px;
          line-height: 28px;
          font-weight: 320;
          span{font-weight: 500;}
        }

        .english {
          font-size: 15px;
          line-height: 22px;
          font-weight: 320;
        }
      }

      .right {
        margin-top: 64px;
        margin-right: 0.3rem;
        font-size: 14px;

        span {
          display: inline-block;
          width: 130px;
        }

        p {
          line-height: 12px;
        }
      }
    }
  }
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {

  .text {
    .left {
      width: 950px !important;

      .title {
        font-size: 30px !important;
      }

      .chinese {
        font-size: 16px !important;
        line-height: 18px !important;
      }

      .chinese-min {
        font-size: 17px !important;
        line-height: 30px !important;
      }

      .english {
        font-size: 15px !important;
        line-height: 22px !important;
      }
    }

    .right {
      margin-top: 68px !important;
      margin-right: 0.3rem !important;
      font-size: 16px !important;

      span {
        display: inline-block;
        width: 140px;
      }

      p {
        line-height: 12px;
      }
    }
  }


}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width:1600px) and (max-width:1899px) {


  .text {
    .left {
      width: 900px !important;

      .title {
        font-size: 27px !important;
        margin-bottom: 20px;
      }

      .chinese {
        font-size: 16px !important;
        line-height: 28px !important;
      }

      .chinese-min {
        font-size: 16px !important;
        line-height: 28px !important;
      }

      .english {
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }

    .right {
      margin-top: 65px !important;
      margin-right: 0.3rem !important;
      font-size: 14px !important;

      span {
        display: inline-block;
        width: 120px;
      }

      p {
        line-height: 12px;
      }
    }
  }

}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {

  .text {
    .left {
      width: 680px !important;

      .title {
        font-size: 24px !important;
        margin-bottom: 20px;
      }

      .chinese {
        font-size: 25px !important;
        line-height: 0px;
        margin-bottom: 0px;
      }

      .chinese-min {
        font-size: 14px !important;
        line-height: 24px !important;
        font-weight: 320;
      }

      .english {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 320;
      }
    }

    .right {
      margin-top: 45px !important;
      margin-right: 0.3rem !important;
      font-size: 13px !important;

      span {
        display: inline-block;
        width: 120px;
      }

      p {
        line-height: 12px;
      }
    }
  }
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {
  .text {
    .left {
      width: 500px !important;

      .title {
        font-size: 24px !important;
        margin-bottom: 18px;
      }

      .chinese {
        font-size: 25px !important;
        line-height: 0px;
        margin-bottom: 0px;
      }

      .chinese-min {
        font-size: 12px !important;
        line-height: 24px !important;
        font-weight: 320;
      }

      .english {
        font-size: 12px !important;
        line-height: 22px !important;
        font-weight: 320;
      }
    }

    .right {
      margin-top: 45px !important;
      margin-right: 0.3rem !important;
      font-size: 12px !important;

      span {
        display: inline-block;
        width: 130px;
      }

      p {
        line-height: 10px;
      }
    }
  }
}

/* 宽度小于768 */
@media only screen and (max-width:768px) {
  .workDetail {
    .con {
      .img {
        width: 100%;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0;

        .left {
          width: 96%;

          .title {
            margin-left: 0px;
            font-size: 22px;
            margin-bottom: 20px;
          }

          .chinese-min {
            font-size: 15px !important;
            line-height: 26px !important;
          }

          .english {
            font-size: 15px;
            line-height: 20px !important;
          }
        }

        .right {
          width: 96%;
          padding: 0 !important;
          margin: 0 !important;
          padding-left: 0 !important;
          font-size: 13px;

          p {
            line-height: 10px;
          }
        }
      }
    }
  }
}
</style>